<template>
  <div class="index_container myMeetList">
    <div class="myMeetList_main">
      <TopBanner></TopBanner>
      <div class="myMeetList_block">
        <div class="myMeetList_search_check">
          <div class="myMeetList_search_check_title">
            <div class="myMeetList_search_title">我的会议</div>
            <div class="myMeetList_search_title">
              <CustomButton size="small" @click="goToHome"
                >回到问答列表</CustomButton
              >
            </div>
          </div>
        </div>
        <div class="myMeetList_list" ref="meetList">
          <ListScroll :url="$urls.live.list">
            <template v-slot:listItem="{ cItems, cIndex }">
              <home-item
                class="homeItem"
                :key="cIndex"
                :item="cItems"
                :mode="1"
              ></home-item>
            </template>
            <template v-slot:logo> <Logo></Logo> </template>
          </ListScroll>
        </div>
      </div>
    </div>
    <OutLogin></OutLogin>
  </div>
</template>
<script>
import CustomButton from "@/components/unit/CustomButton";
import ListScroll from "@/components/unit/ListScroll";
import Popup from "../../unit/Popup.vue";
import homeItem from "../home/homeItem.vue";
import TopBanner from "./topBanner";
import Logo from "../meet/logo";
import OutLogin from "./outLogin";
export default {
  name: "myMeetList",
  components: {
    homeItem,
    CustomButton,
    ListScroll,
    TopBanner,
    OutLogin,
    Logo,
  },
  data() {
    return {
      search: {
        title: "",
        role: [],
        new: 1,
        host: 0,
        id: "",
      },
    };
  },
  created() {
    this.search.id = this.$route.query.id || 0;
  },
  mounted() {},
  methods: {
    goToHome() {
      this.$router.push({
        path: "/home",
      });
    },
  },
};
</script>
<style >
.index_container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.myMeetList_main {
  flex: 1 0 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.myMeetList_block {
  background: #fbf9fa;
  padding-bottom: 1rem;
  flex: 1 0 0;
  display: flex;
  flex-direction: column;
}
.myMeetList_search {
  display: flex;
  padding: 0.5rem;
}
.myMeetList_search .el-input {
  flex: 1 0 0;
}
.myMeetList_search .el-input__inner {
  border-radius: 1rem;
  border: 1.5px solid #212121 !important;
}
.myMeetList_search .el-input__prefix {
  left: 8px !important;
  top: 4px !important;
}
.myMeetList_search .el-icon {
  font-size: 20px;
  color: #212121;
}
.myMeetList_search_title {
  font-size: 0.875rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  margin: 0.1rem 0.5rem;
}
.myMeetList_search_title .customButton_container {
  font-weight: 400;
  height: 1.2rem;
  font-size: 0.8rem;
}
.myMeetList_search_check {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.25rem;
  padding-right: 0.5rem;
}
.myMeetList_search_check .myMeetList_search_check_title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.myMeetList_search_check .el-checkbox__label {
  font-size: 0.625rem !important;
  padding-left: 6px !important;
}
.myMeetList_search_new_host {
  font-size: 0.625rem;
  display: flex;
  align-items: center;
}

.myMeetList_list {
  padding: 0 0.5rem;
  flex: 1 0 0;
  overflow: hidden;
}
.myMeetListItem {
  margin-bottom: 0.5rem;
}
.myMeetListItem:last-child {
  margin-bottom: 0;
}
.myMeetList .activation {
  color: #860241;
}

.pop_content_main {
  /* margin: 1rem 0; */
  padding: 1rem;
  white-space: pre-line;
}
</style>